<template>
    <div class="widget widget__birthday" style="width: 100%">
        <marquee>
            <div style="display: flex; align-items: center; gap: 20px;">
                <div v-for="(todayBirthday, i) in todayBirthdays" :key="i">
                    {{ todayBirthday?.fullName }}, поздравляем с Днём Рождения! 🎉 🎈🎈🎈
                </div>
            </div>
        </marquee>
    </div>
</template>

<script>
import {mapState} from "vuex";
import UserService from "@/_services/UserService";

export default {
    name: 'Birthday',
    props: {},
    data() {
        return {
            todayBirthdays: []
        }
    },
    methods: {
        getBirthdays() {
            UserService.getTodayBirthdays().then(data => {
                this.todayBirthdays = data;
            });
        }
    },
    computed: {
        ...mapState(['user']),
    },
    created() {
        setTimeout(() => this.getBirthdays(), 2 * 1000); // wait until the user data is fetched
        setInterval(() => this.getBirthdays(), 5 * 60 * 1000);
    }
}
</script>
