var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" Комментарии "), _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.fetch();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('CommentDialog', {
    attrs: {
      "id": _vm.id,
      "types": _vm.types
    },
    on: {
      "saved": _vm.onCommentAdd
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.comments,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "20"
          }
        }), _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('DateField', {
          attrs: {
            "label": "Дата",
            "clearable": true,
            "range": true
          },
          model: {
            value: _vm.filterForm.dates,
            callback: function callback($$v) {
              _vm.$set(_vm.filterForm, "dates", $$v);
            },
            expression: "filterForm.dates"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "220"
          }
        }, [_c('v-autocomplete', {
          attrs: {
            "items": _vm.filteredUsers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.filterForm.user.id,
            callback: function callback($$v) {
              _vm.$set(_vm.filterForm.user, "id", $$v);
            },
            expression: "filterForm.user.id"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "220"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.types,
            "clearable": ""
          },
          model: {
            value: _vm.filterForm.type,
            callback: function callback($$v) {
              _vm.$set(_vm.filterForm, "type", $$v);
            },
            expression: "filterForm.type"
          }
        })], 1), _c('td')]), _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            staticClass: "px-5",
            attrs: {
              "width": "20"
            }
          }, [_vm.$helper.permission.canDeleteRequest(item, _vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(_vm._s(_vm.$moment(item.date).dateTimeFormat()))]), _c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(item.user.fullName))]), _c('td', [item.type ? _c('div', [_vm._v(_vm._s(item.type.text))]) : _vm._e()]), _c('td', {
            staticStyle: {
              "white-space": "pre-line"
            }
          }, [_vm._v(_vm._s(item.text))])]);
        })], 2)];
      }
    }], null, false, 902698664)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }