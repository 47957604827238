var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: !_vm.filter ? 'overflow-x: scroll' : ''
  }, [!_vm.title ? _c('v-card-title', [_vm._v(" Оплаты "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('div')], 1) : _vm._e(), _vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm.contract && _vm.$helper.permission.canCreateContract(_vm.user) ? _c('PaymentDialog', {
    attrs: {
      "id": _vm.contract.id,
      "contract": _vm.contract,
      "page": _vm.page
    },
    on: {
      "saved": _vm.onPaymentAdd
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    style: "min-width: ".concat(_vm.getTableWidth()),
    attrs: {
      "headers": _vm.headers,
      "items": _vm.payments,
      "options": _vm.contractPaymentsGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loadingTable,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': _vm.itemPerPage
      },
      "fixed-header": _vm.fixedHeader,
      "height": !_vm.filter ? _vm.tableHeight : null
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.contractPaymentsGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [!_vm.filter ? _c('tr', {
          staticClass: "row_sticky"
        }, [_c('td', {
          staticClass: "px-2",
          attrs: {
            "width": "40"
          }
        }), _vm.isVisibleColumn('contract.id') ? _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.id,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "id", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.id"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.number') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.number,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "number", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.number"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', {
          attrs: {
            "width": "220"
          }
        }) : _vm._e(), _vm.isVisibleColumn('contract.state') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.states,
            "clearable": ""
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.states,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "states", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.states"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.userName') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.userName,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "userName", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.userName"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.principal') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.principal,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "principal", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.principal"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('date') ? _c('td', {
          attrs: {
            "width": "190"
          }
        }, [_c('v-dialog', {
          ref: "dialogDate",
          attrs: {
            "return-value": _vm.contractPaymentsGridsOptions.filter.dates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.contractPaymentsGridsOptions.filter, "dates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.contractPaymentsGridsOptions.filter, "dates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.contractPaymentsGridsOptions.filter.dates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contractPaymentsGridsOptions.filter, "dates", $$v);
                  },
                  expression: "contractPaymentsGridsOptions.filter.dates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.date.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.date, "modal", $$v);
            },
            expression: "dialog.date.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.date.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.date, "model", $$v);
            },
            expression: "dialog.date.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.date.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialogDate.save(_vm.dialog.date.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1) : _vm._e(), _vm.isVisibleColumn('amount') ? _c('td', {
          attrs: {
            "width": "150"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "Вид",
            "items": _vm.paymentIncomeTypes,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.type,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "type", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.type"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.payedAmount') ? _c('td', {
          attrs: {
            "width": "130"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.payedAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "payedAmount", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.payedAmount"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.payedLeft') ? _c('td', {
          attrs: {
            "width": "130"
          }
        }) : _vm._e(), _vm.isVisibleColumn('vozvrat') ? _c('td', {
          attrs: {
            "width": "130"
          }
        }) : _vm._e(), _vm.isVisibleColumn('transport') ? _c('td', {
          attrs: {
            "width": "130"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.additionalExpensesTypes,
            "clearable": ""
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.additionalExpensesTypes,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "additionalExpensesTypes", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.additionalExpensesTypes"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('isBezNal') ? _c('td', {
          attrs: {
            "width": "140"
          }
        }, [_c('v-select', {
          attrs: {
            "items": [{
              value: true,
              text: 'Б/н'
            }, {
              value: false,
              text: 'Наличные'
            }],
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.isBezNal,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "isBezNal", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.isBezNal"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('bezNalType') ? _c('td', {
          attrs: {
            "width": "150"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.bezNalTypes,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.bezNalType,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "bezNalType", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.bezNalType"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('nalType') ? _c('td', {
          attrs: {
            "width": "150"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.nalTypes,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.nalType,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "nalType", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.nalType"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('isSentForBuhgalter') ? _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.isSentForBuhgalter,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter, "isSentForBuhgalter", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.isSentForBuhgalter"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', {
          attrs: {
            "width": "230"
          }
        }) : _vm._e(), _vm.isVisibleColumn('contract.lawyer') ? _c('td', {
          attrs: {
            "width": "260"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.lawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "lawyers", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.lawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.additionalLawyer') ? _c('td', {
          attrs: {
            "width": "260"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.additionalLawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "additionalLawyers", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.additionalLawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.type') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.types,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.type,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "type", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.type"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.subject') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.subjects,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.contractPaymentsGridsOptions.filter.contract.subject,
            callback: function callback($$v) {
              _vm.$set(_vm.contractPaymentsGridsOptions.filter.contract, "subject", $$v);
            },
            expression: "contractPaymentsGridsOptions.filter.contract.subject"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('contract.additionalPayments') ? _c('td', {
          attrs: {
            "width": "200"
          }
        }) : _vm._e(), _vm.isVisibleColumn('contract.comment') ? _c('td', {
          attrs: {
            "width": "230"
          }
        }) : _vm._e(), _vm.isVisibleColumn('contract.commentForBuhgalter') ? _c('td', {
          attrs: {
            "width": "230"
          }
        }) : _vm._e()]) : _vm._e(), _vm._l(items, function (item, key) {
          var _vm$nalTypes$find;
          return _c('tr', {
            key: item.id,
            class: {
              'grey darken-3': _vm.isHighlighted(item)
            }
          }, [_c('td', {
            staticClass: "pl-5 pr-3"
          }, [_vm.$helper.permission.canDeleteRequest(item, _vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref3) {
                var on = _ref3.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.dialog.delete[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.dialog.delete, item.id, $$v);
              },
              expression: "dialog.delete[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.dialog.delete[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _vm.isVisibleColumn('contract.id') ? _c('td', [_c('router-link', {
            attrs: {
              "to": "/contracts/".concat(item.contract.id, "/edit")
            }
          }, [_vm._v(_vm._s(item.contract.id))])], 1) : _vm._e(), _vm.isVisibleColumn('contract.number') ? _c('td', [item.contract.number ? _c('div', [_vm._v(_vm._s(item.contract.number))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateTimeFormat()) + " ")]) : _vm._e(), _vm.isVisibleColumn('contract.state') ? _c('td', [item.contract.state ? _c('v-chip', {
            attrs: {
              "color": item.contract.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.contract.state.text) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('contract.userName') ? _c('td', [item.contract.userName ? _c('div', [_vm._v(_vm._s(item.contract.userName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.principal') ? _c('td', [item.contract.principal ? _c('div', [_vm._v(_vm._s(item.contract.principal))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('date') ? _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.date).dateFormat()) + " ")]) : _vm._e(), _vm.isVisibleColumn('amount') ? _c('td', [item.amount && item.amount >= 0 && (!item.type || item.type.value !== 'TRANSPORT') ? _c('div', [item.type ? _c('div', {
            staticClass: "field-info"
          }, [_vm._v(" " + _vm._s(item.type.text) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(item.amount) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.payedAmount') ? _c('td', [_c('div', [_vm._v(_vm._s(item.contract.payedAmount))])]) : _vm._e(), _vm.isVisibleColumn('contract.payedLeft') ? _c('td', [_c('div', [_vm._v(_vm._s(item.contract.amount - item.contract.payedAmount))])]) : _vm._e(), _vm.isVisibleColumn('vozvrat') ? _c('td', [item.amount < 0 && item.type.value !== 'TRANSPORT' ? _c('div', [_vm._v(_vm._s(item.amount))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('transport') ? _c('td', [item.type && item.type.value === 'TRANSPORT' ? _c('div', [item.additionalExpensesType ? _c('div', {
            staticClass: "field-info"
          }, [_vm._v(" " + _vm._s(item.additionalExpensesType.text) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(item.amount) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('isBezNal') ? _c('td', [item.isBezNal && item.bezNalType != 'KARTA' ? _c('div', [item.bezNalType ? _c('div', {
            staticClass: "field-info",
            staticStyle: {
              "padding": "8px 0 3px"
            }
          }, [_vm._v(" " + _vm._s(_vm.bezNalTypes.find(function (type) {
            return type.value === item.bezNalType;
          }).text) + " ")]) : _vm._e(), _c('v-chip', {
            attrs: {
              "color": "light-blue",
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" Б/н ")])], 1) : _vm._e(), !item.isBezNal || item.bezNalType == 'KARTA' ? _c('div', [item.isBezNal ? _c('div', {
            staticClass: "field-info",
            staticStyle: {
              "padding": "8px 0 3px"
            }
          }, [_vm._v("Карта")]) : _vm._e(), _c('v-chip', {
            attrs: {
              "color": "green",
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" Наличные "), item.nalType && item.nalType.value === 'CARD' ? _c('span', {
            staticClass: "ml-1"
          }, [_vm._v(" (карта) ")]) : _vm._e()])], 1) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('bezNalType') ? _c('td', [_vm._v(" " + _vm._s(_vm.showBezNalTypes(item.bezNalType)) + " ")]) : _vm._e(), _vm.isVisibleColumn('nalType') ? _c('td', [_vm._v(" " + _vm._s(((_vm$nalTypes$find = _vm.nalTypes.find(function (type) {
            return type.value === item.nalType;
          })) === null || _vm$nalTypes$find === void 0 ? void 0 : _vm$nalTypes$find.text) || '') + " ")]) : _vm._e(), _vm.isVisibleColumn('isSentForBuhgalter') ? _c('td', [item.isSentForBuhgalter ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "green"
            }
          }, [_vm._v("mdi-check")]) : _vm._e()], 1) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', [item.comment ? _c('div', [_vm._v(_vm._s(item.comment))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.lawyer') ? _c('td', [item.contract.lawyer ? _c('div', [_vm._v(_vm._s(item.contract.lawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.additionalLawyer') ? _c('td', [item.contract.additionalLawyer ? _c('div', [_vm._v(_vm._s(item.contract.additionalLawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.type') ? _c('td', [item.contract.type ? _c('div', [_vm._v(_vm._s(_vm.getTypeName(item.contract.type)))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.subject') ? _c('td', [item.contract.subject ? _c('div', [_vm._v(_vm._s(_vm.getSubjectName(item.contract.subject)))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.additionalPayments') ? _c('td', [item.contract.additionalPayments ? _c('div', _vm._l(item.contract.additionalPayments, function (additionalPayment) {
            return _c('div', {
              key: additionalPayment.id,
              staticClass: "mb-1"
            }, [_vm._v(" " + _vm._s(additionalPayment.date) + ": " + _vm._s(additionalPayment.amount) + " ")]);
          }), 0) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('contract.comment') ? _c('td', [item.contract.comment ? _c('div', [_vm._v(_vm._s(item.contract.comment))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('commentForBuhgalter') ? _c('td', [item.contract.commentForBuhgalter ? _c('div', [_vm._v(_vm._s(item.contract.commentForBuhgalter))]) : _vm._e(), item.commentForBuhgalter ? _c('div', [_vm._v(_vm._s(item.commentForBuhgalter))]) : _vm._e()]) : _vm._e()]);
        }), _c('tr', {
          staticClass: "result-row"
        }, [_c('td'), _vm._l(_vm.filteredHeaders, function (header, i) {
          return _c('td', {
            key: i
          }, [i === 0 ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value == 'amount' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalAmount)) + " ")]) : _vm._e(), header.value == 'vozvrat' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalVozvrat)) + " ")]) : _vm._e(), header.value == 'transport' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.totalTransport)) + " ")]) : _vm._e()]);
        })], 2)], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }