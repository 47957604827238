var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget widget__birthday",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('marquee', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "20px"
    }
  }, _vm._l(_vm.todayBirthdays, function (todayBirthday, i) {
    return _c('div', {
      key: i
    }, [_vm._v(" " + _vm._s(todayBirthday === null || todayBirthday === void 0 ? void 0 : todayBirthday.fullName) + ", поздравляем с Днём Рождения! 🎉 🎈🎈🎈 ")]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }