<template>
    <div :style="!filter ? 'overflow-x: scroll' : ''">
        <v-card-title v-if="!title">
            Оплаты
            <v-btn icon @click="forceUpdate">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div></div>
        </v-card-title>
        <v-card-title v-if="title">
            {{ title }}
            <v-spacer></v-spacer>
            <PaymentDialog
                v-if="contract && $helper.permission.canCreateContract(user)"
                :id="contract.id"
                :contract="contract"
                @saved="onPaymentAdd"
                :page="page">
            </PaymentDialog>
        </v-card-title>
        <v-data-table
            :style="`min-width: ${getTableWidth()}`"
            class="elevation-1"
            :headers="headers"
            :items="payments"
            :options.sync="contractPaymentsGridsOptions.options"
            :server-items-length="totalElements"
            item-key="id"
            :loading="loadingTable"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options': itemPerPage}"
            :fixed-header="fixedHeader"
            :height="!filter ? tableHeight : null">
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td width="40" class="px-2"></td>
                    <td v-if="isVisibleColumn('contract.id')" width="120">
                        <v-text-field
                            v-model="contractPaymentsGridsOptions.filter.contract.id"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.number')" width="100">
                        <v-text-field
                            v-model="contractPaymentsGridsOptions.filter.contract.number"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('createdAt')" width="220"></td>
                    <td v-if="isVisibleColumn('contract.state')" width="160">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.contract.states"
                            multiple
                            :items="states"
                            clearable>
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.userName')" width="100">
                        <v-text-field
                            v-model="contractPaymentsGridsOptions.filter.contract.userName"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.principal')" width="160">
                        <v-text-field
                            v-model="contractPaymentsGridsOptions.filter.contract.principal"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('date')" width="190">
                        <v-dialog
                            ref="dialogDate"
                            v-model="dialog.date.modal"
                            :return-value.sync="contractPaymentsGridsOptions.filter.dates"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="contractPaymentsGridsOptions.filter.dates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.date.model"
                                type="date"
                                scrollable
                                range
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.date.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialogDate.save(dialog.date.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('amount')" width="150">
                        <v-select
                            label="Вид"
                            v-model="contractPaymentsGridsOptions.filter.type"
                            :items="paymentIncomeTypes"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.payedAmount')" width="130">
                        <v-text-field
                            v-model="contractPaymentsGridsOptions.filter.contract.payedAmount"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.payedLeft')" width="130"></td>
                    <td v-if="isVisibleColumn('vozvrat')" width="130"></td>
                    <td v-if="isVisibleColumn('transport')" width="130">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.additionalExpensesTypes"
                            multiple
                            :items="additionalExpensesTypes"
                            clearable>
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('isBezNal')" width="140">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.isBezNal"
                            :items="[
                                      { value: true, text: 'Б/н' },
                                      { value: false, text: 'Наличные' }
                                  ]"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('bezNalType')"
                        width="150">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.bezNalType"
                            :items="bezNalTypes"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('nalType')"
                        width="150">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.nalType"
                            :items="nalTypes"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('isSentForBuhgalter')" width="120">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.isSentForBuhgalter"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('comment')" width="230"></td>
                    <td v-if="isVisibleColumn('contract.lawyer')" width="260">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.contract.lawyers"
                            multiple
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.additionalLawyer')" width="260">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.contract.additionalLawyers"
                            multiple
                            :items="lawyers" clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>

                    <td v-if="isVisibleColumn('contract.type')" width="100">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.contract.type"
                            :items="types"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.subject')" width="100">
                        <v-select
                            v-model="contractPaymentsGridsOptions.filter.contract.subject"
                            :items="subjects"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.additionalPayments')" width="200"></td>
                    <td v-if="isVisibleColumn('contract.comment')" width="230"></td>
                    <td v-if="isVisibleColumn('contract.commentForBuhgalter')" width="230"></td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td class="pl-5 pr-3">
                        <v-dialog
                            v-model="dialog.delete[item.id]"
                            max-width="320"
                            v-if="$helper.permission.canDeleteRequest(item, user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="dialog.delete[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('contract.id')">
                        <router-link :to="`/contracts/${item.contract.id}/edit`">{{ item.contract.id }}</router-link>
                    </td>
                    <td v-if="isVisibleColumn('contract.number')">
                        <div v-if="item.contract.number">{{ item.contract.number }}</div>
                    </td>
                    <td v-if="isVisibleColumn('createdAt')">
                        {{ $moment(item.createdAt).dateTimeFormat() }}
                    </td>
                    <td v-if="isVisibleColumn('contract.state')">
                        <v-chip v-if="item.contract.state" :color="item.contract.state.chipColor" outlined dark>
                            {{ item.contract.state.text }}
                        </v-chip>
                    </td>
                    <td v-if="isVisibleColumn('contract.userName')">
                        <div v-if="item.contract.userName">{{ item.contract.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.principal')">
                        <div v-if="item.contract.principal">{{ item.contract.principal }}</div>
                    </td>
                    <td v-if="isVisibleColumn('date')">
                        {{ $moment(item.date).dateFormat() }}
                    </td>
                    <td v-if="isVisibleColumn('amount')">
                        <div v-if="item.amount && item.amount >= 0 && (!item.type || item.type.value !== 'TRANSPORT')">
                            <div v-if="item.type" class="field-info">
                                {{ item.type.text }}
                            </div>
                            {{ item.amount }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('contract.payedAmount')">
                        <div>{{ item.contract.payedAmount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.payedLeft')">
                        <div>{{ item.contract.amount - item.contract.payedAmount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('vozvrat')">
                        <div v-if="item.amount < 0 && item.type.value !== 'TRANSPORT'">{{ item.amount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('transport')">
                        <div v-if="item.type && item.type.value === 'TRANSPORT'">
                            <div v-if="item.additionalExpensesType" class="field-info">
                                {{ item.additionalExpensesType.text }}
                            </div>
                            {{ item.amount }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('isBezNal')">
                        <div v-if="item.isBezNal && item.bezNalType != 'KARTA'">
                            <div v-if="item.bezNalType " class="field-info" style="padding: 8px 0 3px;">
                                {{ bezNalTypes.find(type => type.value === item.bezNalType).text }}
                            </div>
                            <v-chip color="light-blue" outlined dark>
                                Б/н
                            </v-chip>
                        </div>
                        <div v-if="!item.isBezNal || item.bezNalType == 'KARTA'">
                            <div v-if="item.isBezNal" class="field-info" style="padding: 8px 0 3px;">Карта</div>
                            <v-chip color="green" outlined dark>
                                Наличные
                                <span v-if="item.nalType && item.nalType.value === 'CARD'" class="ml-1">
                                    (карта)
                                </span>
                            </v-chip>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('bezNalType')">
                        {{ showBezNalTypes(item.bezNalType) }}
                    </td>
                    <td v-if="isVisibleColumn('nalType')">
                        {{ nalTypes.find(type => type.value === item.nalType)?.text || '' }}
                    </td>
                    <td v-if="isVisibleColumn('isSentForBuhgalter')">
                        <v-icon v-if="item.isSentForBuhgalter" small color="green">mdi-check</v-icon>
                    </td>
                    <td v-if="isVisibleColumn('comment')">
                        <div v-if="item.comment">{{ item.comment }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.lawyer')">
                        <div v-if="item.contract.lawyer">{{ item.contract.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.additionalLawyer')">
                        <div v-if="item.contract.additionalLawyer">{{ item.contract.additionalLawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.type')">
                        <div v-if="item.contract.type">{{ getTypeName(item.contract.type) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.subject')">
                        <div v-if="item.contract.subject">{{ getSubjectName(item.contract.subject) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.additionalPayments')">
                        <div v-if="item.contract.additionalPayments">
                            <div v-for="additionalPayment in item.contract.additionalPayments"
                                 :key="additionalPayment.id" class="mb-1">
                                {{ additionalPayment.date }}: {{ additionalPayment.amount }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('contract.comment')">
                        <div v-if="item.contract.comment">{{ item.contract.comment }}</div>
                    </td>
                    <td v-if="isVisibleColumn('commentForBuhgalter')">
                        <div v-if="item.contract.commentForBuhgalter">{{ item.contract.commentForBuhgalter }}</div>
                        <div v-if="item.commentForBuhgalter">{{ item.commentForBuhgalter }}</div>
                    </td>
                </tr>
                <tr class="result-row">
                    <td></td>
                    <td v-for="(header,i) in filteredHeaders" :key="i">
                        <div v-if="i === 0">Всего:</div>
                        <div v-if="header.value == 'amount'">
                            {{ $helper.format.currency(totalAmount) }}
                        </div>
                        <div v-if="header.value == 'vozvrat'">
                            {{ $helper.format.currency(totalVozvrat) }}
                        </div>
                        <div v-if="header.value == 'transport'">
                            {{ $helper.format.currency(totalTransport) }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ContractPaymentService from "../../_services/ContractPaymentService";
import ContractService from "../../_services/ContractService";
import {mapState} from 'vuex';
import _ from "lodash";
import PaymentDialog from "./PaymentDialog";

export default {
    name: 'ContractPaymentsGrid',
    components: {PaymentDialog},
    props: {
        filter: Object,
        title: String,
        exclude: Array,
        columns: Array,
        contract: Object,
    },
    data: function () {
        return {
            payments: [],
            lawyers: [],
            types: [],
            additionalExpensesTypes: [],
            bezNalTypes: [],
            nalTypes: [],
            subjects: [],
            selected: [],
            loading: true,
            totalElements: 0,
            states: [],
            paymentTypes: [],
            paymentIncomeTypes: [],
            page: {},
            checkboxValues: [
                {value: true, text: 'Да'},
                {value: false, text: 'Нет'}
            ],
            dialog: {
                date: {
                    model: [],
                    modal: false
                },
                additionalPaymentsDates: {
                    model: [],
                    modal: false
                },
                delete: {}
            },
            tableHeight: !this.filter ? this.$helper.calcTableHeightWithHorizontalScroll() : null,
            fixedHeader: !this.filter,
            loadingTable: false,
            contractPaymentsGridsOptions:
                !this.filter
                    ? this.$store.state.contractPaymentsGridsOptions
                    : {
                        options: {
                            sortBy: ['id'],
                            sortDesc: [true]
                        },
                        filter: this.filter
                    },
        }
    },
    watch: {
        contractPaymentsGridsOptions: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        }
    },
    methods: {
        forceUpdate() {
            this.fetchGrid();
        },
        fetchGrid() {
            this.loadingTable = true;
            ContractPaymentService.getAll(this.contractPaymentsGridsOptions.options, this.contractPaymentsGridsOptions.filter).then(data => {
                if (this.exclude) {
                    data.page.content = data.page.content.filter(el => this.exclude.indexOf(el.id) === -1);
                }

                this.page = data;
                this.payments = data.page.content;
                this.totalElements = data.page.totalElements;
                this.lawyers = data.lawyers;
                this.types = data.types;
                this.paymentTypes = data.paymentTypes;
                this.paymentIncomeTypes = this.paymentTypes.map(paymentType => {
                    paymentType = {...paymentType};
                    if (paymentType.value === 'TRANSPORT') {
                        paymentType.text = 'Прочие Д/C приход';
                    }
                    return paymentType;
                });
                this.additionalExpensesTypes = data.additionalExpensesType;
                this.bezNalTypes = data.bezNalTypes;
                this.nalTypes = data.nalTypes;
                this.subjects = data.subjects;
                this.states = data.states;
                this.loadingTable = false;
            });
        },
        deleteItem(id) {
            ContractService.deletePayment(id).then(() => {
                this.fetchGrid();
            });
        },
        onPaymentAdd() {
            this.fetchGrid();
        },
        onResize() {
            this.tableHeight = this.$helper.calcTableHeight();
        },
        isVisibleColumn(column) {
            return this.headers.find(el => el.value === column) !== undefined;
        },
        isHighlighted(item) {
            return item.isNew || _.includes(this.selected, item.id)
        },
        getTypeName(type) {
            return this.types.find(item => item.value === type).text;
        },
        getSubjectName(subject) {
            return this.subjects.find(item => item.value === subject).text;
        },
        getTableWidth() {
            return !this.filter ? this.$helper.contractPaymentGridColumns.getPropertiesForRole(this.user.role).tableWidth : 'none';
        },
        showBezNalTypes(bezNalType) {
            const result = this.bezNalTypes.find(type => type.value === bezNalType)?.text || '';
            if (result === 'Терминал') {
                return '';
            }
            return result;
        }
    },
    computed: {
        filteredHeaders() {
            return this.headers.slice(1);
        },
        itemPerPage: function () {
            return [20, 30, 50, 100, 200, 500, 1000];
        },
        headers: function () {
            return !this.columns ? this.$helper.contractPaymentGridColumns.getForRole(this.user.role) : this.columns;
        },
        totalAmount: function () {
            let totalAmount = 0;
            this.payments.forEach((payment) => {
                if (payment.amount >= 0 && (!payment.type || payment.type.value !== 'TRANSPORT')) {
                    totalAmount += payment.amount
                }
            });
            return totalAmount;
        },
        totalVozvrat: function () {
            let totalVozvrat = 0;
            this.payments.forEach((payment) => {
                if (payment.amount < 0 && (!payment.type || payment.type.value !== 'TRANSPORT')) {
                    totalVozvrat += payment.amount
                }
            });
            return totalVozvrat;
        },
        totalTransport: function () {
            let totalTransport = 0;
            this.payments.forEach((payment) => {
                if (payment.type && payment.type.value === 'TRANSPORT') {
                    totalTransport += payment.amount
                }
            });
            return totalTransport;
        },
        totalAlreadyPayedAmount: function () {
            let totalAlreadyPayedAmount = 0;
            this.payments.forEach((payment) => {
                totalAlreadyPayedAmount += payment.contract.payedAmount;
            });
            return totalAlreadyPayedAmount;
        },
        totalPayedAmount: function () {
            let totalPayedAmount = 0;
            this.payments.forEach((payment) => {
                totalPayedAmount += payment.contract.amount - payment.contract.payedAmount;
            });
            return totalPayedAmount;
        },
        ...mapState(['user'])
    },
    created() {
        this.$store.dispatch('loading', false);
        this.loading = false;
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    }
};
</script>
<style lang="scss">
.v-content {
    padding-top: 0 !important;
}
</style>
