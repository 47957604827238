<template>
    <div style="overflow-x: scroll">
        <v-card-title v-if="!title">
            База договоров
            <v-btn icon @click="forceUpdate">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <v-btn v-if="$helper.permission.canCreateContract(user)" color="green" to="/contracts/create">
                    Добавить договор
                </v-btn>
                <v-btn
                    class="ml-3"
                    v-if="$helper.permission.canDeleteContract(user)"
                    outlined
                    :loading="recalculatePayedAmountLoading"
                    color="orange"
                    @click="recalculatePayedAmount">
                    Пересчитать остатки
                </v-btn>

                <v-menu
                    max-height="300"
                    bottom
                    :close-on-content-click="false"
                    left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-4"
                            icon
                            v-on="on">
                            <v-icon>mdi-filter-cog-outline</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group
                            v-model="selectedHeaders"
                            multiple>
                            <v-list-item
                                v-for="(header, index) in headers"
                                :key="header.value">
                                <template v-slot:default="{ active, toggle }">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ header.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item @click="resetSelectedHeaders">
                                <v-btn
                                    class="mx-4"
                                    color="red"
                                    icon>
                                    <v-icon>mdi-filter-off</v-icon>
                                </v-btn>
                                Сбросить
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>

            </div>
        </v-card-title>
        <v-card-title v-if="title">
            {{ title }}
        </v-card-title>
        <v-data-table
            :style="`min-width: ${getTableWidth()}`"
            class="elevation-1"
            :headers="visibleHeaders"
            :items="contracts"
            :options.sync="contractGridsOptions.options"
            :server-items-length="totalElements"
            v-model="selected"
            item-key="id"
            :loading="loading"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options': itemPerPage}"
            :fixed-header="fixedHeader"
            :height="tableHeight">
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td
                        v-if="isVisibleColumn('action')"
                        :width="getColumnWidthOrDefault('action', 30)"
                        class="px-2"></td>
                    <td
                        v-if="isVisibleColumn('inFavorites')"
                        :width="getColumnWidthOrDefault('inFavorites', 30)"
                        class="pr-0">
                        <v-checkbox
                            v-model="contractGridsOptions.filter['usersAddToFavorites[0].id']"
                            :value="user.id"
                            hide-details class="ma-0"/>
                    </td>
                    <td
                        v-if="isVisibleColumn('id')"
                        :width="getColumnWidthOrDefault('id', 110)">
                        <v-text-field
                            v-model="contractGridsOptions.filter.id"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('number')"
                        :width="getColumnWidthOrDefault('number', 120)">
                        <v-text-field
                            v-model="contractGridsOptions.filter.number"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('state')"
                        :width="getColumnWidthOrDefault('state', 160)">
                        <v-select
                            v-model="contractGridsOptions.filter.states"
                            multiple
                            :items="states"
                            clearable
                        ></v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('phase')"
                        :width="getColumnWidthOrDefault('phase', 160)">
                        <v-select
                            v-model="contractGridsOptions.filter.phases"
                            multiple
                            :items="phases"
                            clearable
                        ></v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('userName')"
                        :width="getColumnWidthOrDefault('userName', 100)">
                        <v-text-field
                            v-model="contractGridsOptions.filter.userName"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('principal')"
                        :width="getColumnWidthOrDefault('principal', 160)">
                        <v-text-field v-model="contractGridsOptions.filter.principal" type="text"></v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('sentToOkkActions')"
                        :width="getColumnWidthOrDefault('sentToOkkActions', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.sentToOkkActionsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('flow.applicationDate')"
                        :width="getColumnWidthOrDefault('flow.applicationDate', 200)">
                        <DateField
                            label=""
                            :range="true"
                            :clearable="true"
                            v-model="contractGridsOptions.filter.flow.applicationDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('flow.applicationDate')"
                        :width="getColumnWidthOrDefault('flow.answerDate', 200)">
                        <DateField
                            label=""
                            :range="true"
                            :clearable="true"
                            v-model="contractGridsOptions.filter.flow.answerDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('flow.applicationDate')"
                        :width="getColumnWidthOrDefault('flow.moneyBackDate', 200)">
                        <DateField
                            label=""
                            :range="true"
                            :clearable="true"
                            v-model="contractGridsOptions.filter.flow.moneyBackDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('createdAt')"
                        :width="getColumnWidthOrDefault('createdAt', 220)">
                        <DateField
                            label=""
                            :range="true"
                            clearable
                            v-model="contractGridsOptions.filter.createdAtDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('date')"
                        :width="getColumnWidthOrDefault('date', 170)">
                        <DateField
                            label=""
                            :range="true"
                            clearable
                            v-model="contractGridsOptions.filter.dates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('amount')"
                        :width="getColumnWidthOrDefault('amount', 130)">
                        <v-text-field v-model="contractGridsOptions.filter.amount" type="text"></v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('alreadyPayed')"
                        :width="getColumnWidthOrDefault('alreadyPayed', 130)">
                    </td>
                    <td
                        v-if="isVisibleColumn('earnedAmount')"
                        :width="getColumnWidthOrDefault('earnedAmount', 130)">
                    <td
                        v-if="isVisibleColumn('additionalPayments')"
                        :width="getColumnWidthOrDefault('additionalPayments', 200)">
                        <DateField
                            label=""
                            :range="true"
                            clearable
                            v-model="contractGridsOptions.filter.additionalPaymentsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('payedAmount')"
                        :width="getColumnWidthOrDefault('payedAmount', 130)">
                        <v-text-field
                            v-model="contractGridsOptions.filter.payedAmount"
                            type="text">
                        </v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('vozvrat')"
                        :width="getColumnWidthOrDefault('vozvrat', 200)">
                    </td>
                    <td
                        v-if="isVisibleColumn('type')"
                        :width="getColumnWidthOrDefault('type', 100)">
                        <v-select
                            v-model="contractGridsOptions.filter.types"
                            multiple
                            :items="types"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('subject')"
                        :width="getColumnWidthOrDefault('subject', 100)">
                        <v-select
                            v-model="contractGridsOptions.filter.subjects"
                            multiple
                            :items="subjects"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('courtDate')"
                        :width="getColumnWidthOrDefault('courtDate', 170)">
                        <DateField
                            label=""
                            :range="true"
                            clearable
                            v-model="contractGridsOptions.filter.courtDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('sentToOKKBy')"
                        :width="getColumnWidthOrDefault('sentToOKKBy', 260)">
                    </td>
                    <td
                        v-if="isVisibleColumn('lawyerOGPA')"
                        :width="getColumnWidthOrDefault('lawyerOGPA', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.lawyerOGPAs"
                            multiple
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('isPaymentOGPA')"
                        :width="getColumnWidthOrDefault('isPaymentOGPA', 140)">
                        <v-select
                            v-model="contractGridsOptions.filter.isPaymentOGPA"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('isEmployeeControl')"
                        :width="getColumnWidthOrDefault('isEmployeeControl', 140)">
                        <v-select
                            v-model="contractGridsOptions.filter.isEmployeeControl"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('additionalLawyerOGPA')"
                        :width="getColumnWidthOrDefault('additionalLawyerOGPA', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.additionalLawyerOGPAs"
                            multiple
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('lawyerOGPAAssistant')"
                        :width="getColumnWidthOrDefault('lawyerOGPAAssistant', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.lawyerOGPAAssistant"
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('actPaymentRemainder')"
                        :width="getColumnWidthOrDefault('actPaymentRemainder', 200)">
                    </td>
                    <td
                        v-if="isVisibleColumn('lawyer')"
                        :width="getColumnWidthOrDefault('lawyer', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.lawyers"
                            multiple
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('additionalLawyer')"
                        :width="getColumnWidthOrDefault('additionalLawyer', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.additionalLawyers"
                            multiple
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('lawyerAssistant')"
                        :width="getColumnWidthOrDefault('lawyerAssistant', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.lawyerAssistant"
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('isBezNal')"
                        :width="getColumnWidthOrDefault('isBezNal', 80)">
                        <v-select
                            v-model="contractGridsOptions.filter.isBezNal" :items="checkboxValues" clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('lawyerEPO')"
                        :width="getColumnWidthOrDefault('lawyerEPO', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.lawyerEPOs"
                            multiple
                            :items="lawyersEPO"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('additionalLawyerEPO')"
                        :width="getColumnWidthOrDefault('additionalLawyerEPO', 260)">
                        <v-select
                            v-model="contractGridsOptions.filter.additionalLawyerEPO" :items="lawyersEPO"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('transport')"
                        :width="getColumnWidthOrDefault('transport', 110)">
                        <v-text-field
                            v-model="contractGridsOptions.filter.transport"
                            type="text">
                        </v-text-field>
                    </td>
                    <td
                        v-if="isVisibleColumn('sentForDocumentsPreparationAt')"
                        :width="getColumnWidthOrDefault('sentForDocumentsPreparationAt', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.sentForDocumentsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('taskLegalExpert.readyDate')"
                        :width="getColumnWidthOrDefault('taskLegalExpert.readyDate', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.taskLegalExpertReadyDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('taskAddLegalExpert.readyDate')"
                        :width="getColumnWidthOrDefault('taskAddLegalExpert.readyDate', 240)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="false"
                            v-model="contractGridsOptions.filter.taskAddLegalExpert.readyDate"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('documentsAreReadyAt')"
                        :width="getColumnWidthOrDefault('documentsAreReadyAt', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.documentsAreReadyDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('transferredToCourtActions')"
                        :width="getColumnWidthOrDefault('transferredToCourtActions', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.transferredToCourtActionsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('sentToCourtActions')"
                        :width="getColumnWidthOrDefault('sentToCourtActions', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.sentToCourtActionsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('transferredToOGPAAt')"
                        :width="getColumnWidthOrDefault('transferredToOGPAAt', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.transferredToOGPADates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('packageFromLenyrActions')"
                        :width="getColumnWidthOrDefault('packageFromLenyrActions', 220)">
                        <DateField
                            label=""
                            :clearable="true"
                            :range="true"
                            v-model="contractGridsOptions.filter.packageFromLenyrActionsDates"
                        ></DateField>
                    </td>
                    <td
                        v-if="isVisibleColumn('area')"
                        :width="getColumnWidthOrDefault('area', 160)">
                        <v-select
                            v-model="contractGridsOptions.filter.area"
                            :items="areas"
                            clearable
                            item-value="id"
                            item-text="name"
                        ></v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('comment')"
                        :width="getColumnWidthOrDefault('comment', 230)">
                    </td>
                    <td
                        v-if="isVisibleColumn('commentForBuhgalter')"
                        :width="getColumnWidthOrDefault('commentForBuhgalter', 230)">
                    </td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td
                        class="pl-5 pr-3"
                        v-if="isVisibleColumn('action')" >
                        <v-dialog v-model="deleteDialog[item.id]" max-width="320"
                                  v-if="$helper.permission.canDeleteContract(user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('inFavorites')">
                        <v-icon
                            v-if="item.inFavorites"
                            @click="removeFromFavorites(item)"
                            color="yellow"
                            class="ml-1">
                            mdi-star
                        </v-icon>
                        <v-icon
                            v-if="!item.inFavorites"
                            @click="addToFavorites(item)"
                            color="grey"
                            class="ml-1">
                            mdi-star-outline
                        </v-icon>
                    </td>
                    <td v-if="isVisibleColumn('id')" >
                        <a
                            style="padding: 20px"
                            @click="editContract(item.id, items[key-1], items[key + 1])">
                            {{ item.id }}
                        </a>
                    </td>
                    <td v-if="isVisibleColumn('number')">
                        <div v-if="item.number">{{ item.number }}</div>
                    </td>
                    <td v-if="isVisibleColumn('state')">
                        <v-chip v-if="item.state" :color="item.state.chipColor" outlined dark>
                            {{ item.state.text }}
                        </v-chip>
                    </td>
                    <td v-if="isVisibleColumn('phase')">
                        <v-chip v-if="item.phase" :color="item.phase.chipColor" outlined dark>
                            {{ item.phase.text }}
                        </v-chip>
                    </td>
                    <td v-if="isVisibleColumn('userName')">
                        <div v-if="item.userName">{{ item.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('principal')">
                        <div v-if="item.principal">{{ item.principal }}</div>
                    </td>
                    <td v-if="isVisibleColumn('sentToOkkActions')">
                        <div v-if="item.sentToOkkActions">
                            <div
                                v-for="sentToOkkAction in item.sentToOkkActions"
                                :key="sentToOkkAction.id"
                                class="mb-1">
                                {{ $moment(sentToOkkAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('flow.applicationDate')">
                        <div v-if="item.contractFlows" class="text-caption">
                            <div v-for="flow in item.contractFlows" :key="flow.id">
                                <div v-if="flow.applicationDate" class="mt-1">
                                    <div v-if="flow.user">{{ flow.user.fullName }}</div>
                                    {{ $moment(flow.applicationDate).dateFormat() }}
                                    <span v-if="flow.otherApplication">Иное заявление</span>
                                    <v-divider class="my-1"></v-divider>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('flow.applicationDate')">
                        <div v-if="item.contractFlows" class="text-caption">
                            <div v-for="flow in item.contractFlows" :key="flow.id">
                                <div v-if="flow.answerDate" class="mt-1">
                                    <div v-if="flow.user">{{ flow.user.fullName }}</div>
                                    {{ $moment(flow.answerDate).dateFormat() }}
                                    <span v-if="flow.otherApplication">Иное заявление</span>
                                    <v-divider class="my-1"></v-divider>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('flow.applicationDate')">
                        <div v-if="item.contractFlows" class="text-caption">
                            <div v-for="flow in item.contractFlows" :key="flow.id">
                                <div v-if="flow.moneyBackDate" class="mt-1">
                                    <div v-if="flow.user">{{ flow.user.fullName }}</div>
                                    {{ $moment(flow.moneyBackDate).dateFormat() }}
                                    <span v-if="flow.otherApplication">Иное заявление</span>
                                    <v-divider class="my-1"></v-divider>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('createdAt')">{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td v-if="isVisibleColumn('date')">{{ $moment(item.date).dateFormat() }}</td>
                    <td v-if="isVisibleColumn('amount')">
                        <div v-if="item.amount">{{ item.amount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('alreadyPayed')">
                        <div>{{ item.payedAmount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('earnedAmount')">
                        <div>{{ item.earnedAmount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('additionalPayments')">
                        <div v-if="item.additionalPayments">
                            <div v-for="additionalPayment in item.additionalPayments" :key="additionalPayment.id"
                                 class="mb-1">
                                {{ additionalPayment.date }}: {{ additionalPayment.amount }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('payedAmount')">
                        <div>{{ item.amount - item.payedAmount }}</div>
                    </td>
                    <td v-if="isVisibleColumn('vozvrat')">
                        <div v-if="getContractVozvrats(item.payments)" class="text-caption">
                            <div v-for="payment in getContractVozvrats(item.payments)"
                                 :key="payment.id"
                                 class="mt-1">
                                <div>{{ payment.date }}</div>
                                {{ payment.amount }}
                                <v-divider class="my-1"></v-divider>
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('type')">
                        <div v-if="item.type">{{ getTypeName(item.type) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('subject')">
                        <div v-if="item.subject">{{ getSubjectName(item.subject) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('courtDate')">
                        {{ $moment(item.courtDateTime).dateTimeFormat() }}
                    </td>
                    <td v-if="isVisibleColumn('sentToOKKBy')">
                        <div v-if="item.sentToOKKBy">{{ item.sentToOKKBy.fullName }}</div>
                        <div v-if="item.sentToOKKDate">{{ $moment(item.sentToOKKDate).dateFormat() }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPA')">
                        <div v-if="item.lawyerOGPA">
                            {{ item.lawyerOGPA.fullName }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('isPaymentOGPA')">
                        <v-icon v-if="item.isPaymentOGPA" small color="green">mdi-check</v-icon>
                    </td>
                    <td v-if="isVisibleColumn('isEmployeeControl')">
                        <v-icon v-if="item.isEmployeeControl" small color="green">mdi-check</v-icon>
                    </td>
                    <td v-if="isVisibleColumn('additionalLawyerOGPA')">
                        <div v-if="item.additionalLawyerOGPA">
                            {{ item.additionalLawyerOGPA.fullName }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPAAssistant')">
                        <div v-if="item.lawyerOGPAAssistant">{{ item.lawyerOGPAAssistant.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('actPaymentRemainder')">
                        {{ getActRemainder(item) }}
                    </td>
                    <td v-if="isVisibleColumn('lawyer')">
                        <div v-if="item.lawyer">{{ item.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('additionalLawyer')">
                        <div v-if="item.additionalLawyer">{{ item.additionalLawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerAssistant')">
                        <div v-if="item.lawyerAssistant">{{ item.lawyerAssistant.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('isBezNal')">
                        <v-chip v-if="item.isBezNal" color="light-blue" outlined dark>Безнал</v-chip>
                    </td>
                    <td v-if="isVisibleColumn('lawyerEPO')">
                        <div v-if="item.lawyerEPO">{{ item.lawyerEPO.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('additionalLawyerEPO')">
                        <div v-if="item.additionalLawyerEPO">{{ item.additionalLawyerEPO.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('transport')">
                        <div v-if="item.transport">{{ item.transport }}</div>
                    </td>
                    <td v-if="isVisibleColumn('sentForDocumentsPreparationAt')">
                        <div v-if="item.sentForDocumentsActions">
                            <div v-for="sentForDocumentsAction in item.sentForDocumentsActions"
                                 :key="sentForDocumentsAction.id" class="mb-1">
                                {{ $moment(sentForDocumentsAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('taskLegalExpert.readyDate')">
                        <div v-if="item.taskLegalExpert.readyDate">
                            {{ $moment(item.taskLegalExpert.readyDate).dateFormat() }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('taskAddLegalExpert.readyDate')">
                        <div v-if="item.taskAddLegalExpert.readyDate">
                            {{ $moment(item.taskAddLegalExpert.readyDate).dateFormat() }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('documentsAreReadyAt')">
                        <div v-if="item.documentsAreReadyActions">
                            <div v-for="documentsAreReadyAction in item.documentsAreReadyActions"
                                 :key="documentsAreReadyAction.id" class="mb-1">
                                {{ $moment(documentsAreReadyAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('transferredToCourtActions')">
                        <div v-if="item.transferredToCourtActions">
                            <div
                                v-for="transferredToCourtAction in item.transferredToCourtActions"
                                :key="transferredToCourtAction.id"
                                class="mb-1">
                                {{ $moment(transferredToCourtAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('sentToCourtActions')">
                        <div v-if="item.sentToCourtActions">
                            <div
                                v-for="sentToCourtAction in item.sentToCourtActions"
                                :key="sentToCourtAction.id"
                                class="mb-1">
                                {{ $moment(sentToCourtAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('transferredToOGPAAt')">
                        <div v-if="item.transferredToOGPAActions">
                            <div v-for="transferredToOGPAAction in item.transferredToOGPAActions"
                                 :key="transferredToOGPAAction.id" class="mb-1">
                                {{ $moment(transferredToOGPAAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('packageFromLenyrActions')">
                        <div v-if="item.packageFromLenyrActions">
                            <div v-for="packageFromLenyrAction in item.packageFromLenyrActions"
                                 :key="packageFromLenyrAction.id" class="mb-1">
                                {{ $moment(packageFromLenyrAction.createdAt).dateTimeFormat() }}
                            </div>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('area')">
                        <div v-if="item.area">{{ item.area.name }}</div>
                    </td>
                    <td v-if="isVisibleColumn('comment')">
                        <div v-if="item.comment">{{ item.comment }}</div>
                    </td>
                    <td v-if="isVisibleColumn('commentForBuhgalter')">
                        <div v-if="item.commentForBuhgalter">{{ item.commentForBuhgalter }}</div>
                    </td>
                </tr>
                <tr class="result-row">
                    <td v-for="(header,i) in visibleHeaders" :key="i">
                        <div v-if="i === 3">Всего:</div>
                        <div v-if="header.value == 'amount'">
                            {{ $helper.format.currency(totalAmount) }}
                        </div>
                        <div v-if="header.value == 'alreadyPayed'">
                            {{ $helper.format.currency(totalAlreadyPayedAmount) }}
                        </div>
                        <div v-if="header.value == 'payedAmount'">
                            {{ $helper.format.currency(totalPayedAmount) }}
                        </div>
                        <div v-if="header.value == 'vozvrat'">
                            {{ $helper.format.currency(totalVozvrat) }}
                        </div>
                        <div v-if="header.value == 'actPaymentRemainder'">
                            {{ $helper.format.currency(totalActPaymentRemainder) }}
                        </div>
                        <div v-if="header.value == 'transport'">
                            {{ $helper.format.currency(totalTransport) }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <v-bottom-sheet
            v-model="dialog.contractEdit.modal"
            fullscreen
            scrollable
            persistent
            :retain-focus="false">
            <v-card style="background: none">
                <v-card-text style="height: 100vh;">
                    <ContractUpdatePage
                        v-if="editedContract"
                        :id="editedContract"
                        :inPopup="true"
                        :nextContract="nextContract"
                        :prevContract="prevContract"
                        :key="editedContractKey"
                        @changeEdited="changeEdited"
                        @updated="forceUpdate"
                        @closeAndUpdate="closeEditAndFetch"
                        @closeEdit="closeEdit">
                    </ContractUpdatePage>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import ContractService from "../_services/ContractService";
import {mapState} from 'vuex';
import _ from "lodash";
import ContractUpdatePage from "./ContractUpdatePage";
import DateField from "../_chunks/DateField";

export default {
    name: 'ContractGrid',
    components: {DateField, ContractUpdatePage},
    props: {
        filter: Object,
        title: String,
        exclude: Array,
        isOKKGrid: Boolean,
    },
    data: function () {
        return {
            contracts: [],
            lawyers: [],
            lawyersOGPA: [],
            lawyersEPO: [],
            types: [],
            subjects: [],
            selected: [],
            areas: [],
            loading: true,
            totalElements: 0,
            states: [],
            phases: [],
            checkboxValues: [
                {value: true, text: 'Да'},
                {value: false, text: 'Нет'}
            ],
            dialog: {
                date: {
                    model: [],
                    modal: false
                },
                createdAt: {
                    model: [],
                    modal: false
                },
                additionalPaymentsDates: {
                    model: [],
                    modal: false
                },
                contractEdit: {
                    modal: false
                },
                courtDates: {
                    model: null,
                    modal: false
                },
                sentForDocumentsPreparationAt: {
                    model: [],
                    modal: false
                },
            },
            deleteDialog: {},
            deleteBulkDialog: false,
            tableHeight: !this.filter
                ? this.$helper.calcTableHeightWithHorizontalScroll()
                : null,
            fixedHeader: !this.filter,
            editedContract: null,
            nextContract: null,
            prevContract: null,
            editedContractKey: 0,
            recalculatePayedAmountLoading: false,
            tableKey: 0,
            searchTimeout: 0,
            searchDebounceTime: 500
        }
    },
    watch: {
        contractGridsOptions: {
            handler(options) {
                clearTimeout(this.searchTimeout);
                if (options.filter?.userName) { // for 'userName' contains matcher is used, slow down the user input
                    this.searchTimeout = window.setTimeout(() => {
                        this.fetchGrid();
                    }, this.searchDebounceTime);
                } else {
                    this.fetchGrid();
                }
            },
            deep: true
        }
    },
    methods: {
        forceUpdate() {
            this.fetchGrid();
        },
        fetchGrid() {
            this.loading = true;
            const customUrl = this.isOKKGrid ? `/contracts/okk` : false;
            ContractService.getAll(this.contractGridsOptions.options, this.contractGridsOptions.filter, customUrl).then(data => {
                if (this.exclude) {
                    data.page.content = data.page.content.filter(el => this.exclude.indexOf(el.id) === -1);
                }
                this.contracts = data.page.content;
                this.totalElements = data.page.totalElements;
                this.lawyers = data.lawyers;
                this.lawyersOGPA = data.lawyersOGPA;
                this.lawyersEPO = data.lawyersEPO;
                this.types = data.types;
                this.subjects = data.subjects;
                this.areas = data.areas;
                this.states = data.states;
                this.phases = data.phases;
                this.loading = false;
            });
        },
        deleteSelected() {
            ContractService.deleteBulk(this.selected).then(() => {
                this.fetchGrid();
                this.deleteBulkDialog = false;
                this.selected = [];
            });
        },
        deleteItem(id) {
            ContractService.delete(id).then(() => {
                this.fetchGrid();
            });
        },
        onResize() {
            this.tableHeight = this.$helper.calcTableHeight();
        },
        getColumnWidthOrDefault(column, defaultValue = 0) {
            return this.headers
                .find(el => el.value === column).width ?? defaultValue;
        },
        isVisibleColumn(column) {
            return this.visibleHeaders.find(el => el.value === column) !== undefined;
        },
        editContract(id, prevItem, nextItem) {
            this.editedContract = id.toString();
            this.prevContract = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextContract = !_.isUndefined(nextItem) ? nextItem.id : null;
            this.dialog.contractEdit.modal = true;
        },
        closeEdit() {
            this.dialog.contractEdit.modal = false;
            this.editedContract = null;
            this.editedContractKey++;
        },
        changeEdited(id) {
            this.editedContract = id;
            let contractIndex = this.contracts.findIndex(el => {
                return el.id === id;
            });

            let prevItem = this.contracts[contractIndex - 1];
            let nextItem = this.contracts[contractIndex + 1];

            this.prevContract = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextContract = !_.isUndefined(nextItem) ? nextItem.id : null;

            this.editedContractKey++;
        },
        closeEditAndFetch() {
            this.closeEdit();
            this.fetchGrid()
        },
        isHighlighted(item) {
            return item.isNew || _.includes(this.selected, item.id)
        },
        getTypeName(type) {
            return this.types.find(item => item.value === type).text;
        },
        getSubjectName(subject) {
            return this.subjects.find(item => item.value === subject).text;
        },
        recalculatePayedAmount() {
            this.recalculatePayedAmountLoading = true;
            ContractService.recalculatePayedAmount().then(() => {
                this.recalculatePayedAmountLoading = false;
                this.fetchGrid();
            });
        },
        resetSelectedHeaders() {
            this.$ls.removeKey('contract_columns');
            this.tableKey++;
        },
        getTableWidth() {
            return this.visibleHeaders
                .map(header => header.width ?? 100)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0) + 300 + 'px';
        },
        getActRemainder(contract) {
            let totalAmount = 0;
            let paymentRemainder = contract.amount || 0;
            if (contract && contract.acts) {
                contract.acts.filter(el => el?.type?.value === 'ACT').forEach(act => {
                    totalAmount += act.amount;
                })
            }
            return paymentRemainder - totalAmount;
        },
        addToFavorites(contract) {
            ContractService.addToFavorites(contract.id).then(() => {
                contract.inFavorites = true;
            });
        },
        removeFromFavorites(contract) {
            ContractService.removeFromFavorites(contract.id).then(() => {
                contract.inFavorites = false;
            });
        },
        getContractVozvrats(payments) {
            return payments.filter(payment => payment.amount < 0 && payment.type !== 'TRANSPORT');
        }
    },
    computed: {
        itemPerPage: function () {
            return [20, 30, 50, 100, 200, 500];
        },
        headers: function () {
            return this.isOKKGrid
                ? this.$helper.contractOKKGridColumns.getForRole(this.user.role)
                : this.$helper.contractGridColumns.getForRole(this.user.role);
        },
        visibleHeaders: function () {
            if (this.isOKKGrid) {
                return this.headers;
            }
            return this.headers
                .filter((o, i) => this.selectedHeaders.includes(i)) ?? [];
        },
        selectedHeaders: {
            get: function () {
                this.tableKey;
                const headerValues = this.headers
                    .map(header => header.value);
                const selectedHeaderValues = this.$ls.get('contract_columns', headerValues) ?? [];
                return headerValues
                    .map((o, i) => [o, i])
                    .filter(([o]) => selectedHeaderValues.includes(o))
                    .map(([, i]) => i);
            },
            set: function (selectedHeaderIndexes) {
                const selectedHeaderValues = this.headers
                    .filter((o, i) => selectedHeaderIndexes.includes(i))
                    .map(header => header.value) ?? [];
                this.$ls.put('contract_columns', selectedHeaderValues);
                this.tableKey++;
            }
        },
        contractGridsOptions: function () {
            const gridOptions = this.isOKKGrid
                ? this.$store.state.contractOKKGridsOptions
                : this.$store.state.contractGridsOptions;
            return !this.filter
                ? gridOptions
                : {
                    options: {
                        sortBy: ['id'],
                        sortDesc: [true]
                    },
                    filter: this.filter
                }
        },
        totalAmount: function () {
            let totalAmount = 0;
            this.contracts.forEach((contract) => {
                totalAmount += contract.amount
            });
            return totalAmount;
        },
        totalAlreadyPayedAmount: function () {
            let totalAlreadyPayedAmount = 0;
            this.contracts.forEach((contract) => {
                totalAlreadyPayedAmount += contract.payedAmount;
            });
            return totalAlreadyPayedAmount;
        },
        totalPayedAmount: function () {
            let totalPayedAmount = 0;
            this.contracts.forEach((contract) => {
                totalPayedAmount += contract.amount - contract.payedAmount;
            });
            return totalPayedAmount;
        },
        totalVozvrat: function () {
            let totalVozvrat = 0;
            this.contracts.forEach((contract) => {
                this.getContractVozvrats(contract.payments).forEach((vozvrat) => {
                    totalVozvrat += vozvrat.amount;
                });
            });
            return totalVozvrat;
        },
        totalActPaymentRemainder: function () {
            let totalActPaymentRemainder = 0;
            this.contracts.forEach(contract => {
                totalActPaymentRemainder += this.getActRemainder(contract);
            });
            return totalActPaymentRemainder;
        },
        totalTransport: function () {
            let totalTransport = 0;
            this.contracts.forEach(contract => {
                totalTransport += contract.transport;
            });
            return totalTransport;
        },
        ...mapState(['user'])
    },
    created() {
        this.$store.dispatch('loading', false);
        this.fetchGrid();
        this.$store.subscribeAction((action) => {
            if (action.type === 'newContractReceived' && !this.filter) {
                let contract = action.payload;
                contract.isNew = true;
                this.contracts.unshift(action.payload);
            }
        })
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    }
};
</script>

<style lang="scss" scoped>
.v-content {
    padding-top: 0 !important;
}
</style>
