<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Комментарии
                <v-btn
                    icon
                    @click="fetch()"
                    class="ml-4">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <CommentDialog
                    :id="id"
                    :types="types"
                    @saved="onCommentAdd">
                </CommentDialog>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="comments"
                class="elevation-1"
                item-key="id"
                :loading="loading"
                loading-text="Загрузка ..."
                :items-per-page="5">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr>
                        <td width="20"></td>
                        <td width="160">
                            <DateField
                                label="Дата"
                                :clearable="true"
                                :range="true"
                                v-model="filterForm.dates"
                            ></DateField>
                        </td>
                        <td width="220">
                            <v-autocomplete
                                v-model="filterForm.user.id"
                                :items="filteredUsers"
                                clearable
                                item-value="id"
                                item-text="fullName">
                            </v-autocomplete>
                        </td>
                        <td width="220">
                            <v-select
                                v-model="filterForm.type"
                                :items="types"
                                clearable
                            ></v-select>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-for="item in items" :key="item.id">
                        <td width="20" class="px-5">
                            <v-dialog
                                v-model="deleteDialog[item.id]"
                                max-width="320"
                                v-if="$helper.permission.canDeleteRequest(item, user)">
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                        <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                        <td width="160">{{ $moment(item.date).dateTimeFormat() }}</td>
                        <td width="220">{{ item.user.fullName }}</td>
                        <td>
                            <div v-if="item.type">{{ item.type.text }}</div>
                        </td>
                        <td style="white-space: pre-line;">{{ item.text }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import ContractService from "../../_services/ContractService";
import CommentService from "../../_services/CommentService";
import CommentDialog from "./CommentDialog";
import {mapState} from 'vuex';
import DateField from "@/_chunks/DateField.vue";

export default {
    components: {DateField, CommentDialog},
    props: {
        id: Number,
        types: Array,
        users: Array
    },
    data() {
        return {
            headers: [
                {sortable: false},
                {text: 'Дата', value: 'date'},
                {text: 'Пользователь', value: 'user'},
                {text: 'Тип', value: 'type'},
                {text: 'Текст', value: 'text'}
            ],
            filteredUsers: [],
            filterForm: {
                type: null,
                dates: null,
                user: {
                    id: null
                }
            },
            comments: [],
            deleteDialog: {},
            loading: true
        }
    },
    watch: {
        filterForm: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            this.loading = true;
            this.fetchItems();
        },
        fetchItems() {
            ContractService.getComments(this.id, this.filterForm).then(data => {
                this.comments = data;
                this.setUniqueCommentsUsers();
                this.loading = false;
            });
        },
        setUniqueCommentsUsers() {
            const commentUsers =
                this.comments
                    ?.map(comment => comment.user)
            this.filteredUsers =
                commentUsers
                    ?.filter((user, index) => commentUsers.findIndex(obj => user.id === obj.id) === index);
        },
        onCommentAdd() {
            this.fetchItems();
        },
        deleteItem(id) {
            CommentService.delete(id).then(() => {
                this.fetchItems();
            });
        }
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
        this.fetch();
    }
};
</script>
